<template>
  <v-app class="index">
    <div ref="tops" class=" section1">
      <div class="menu">
        <Menu v-if="showMenu" />
        <Umenu v-else />
      </div>
      <div class="sec mt-2">
        <div>
          <div outlined tile class="sectext">
            <div class="h1 pa-2">{{ $t('home.h1') }}</div>
            <div class="word">
              <div class="line4" />
              <div class="ti">{{ $t('home.ask1') }}</div>
              <div class="text">{{ $t('home.ans1') }}</div>
              <div class="line4" />
              <div class="ti">{{ $t('home.ask2') }}</div>
              <div class="text">{{ $t('home.ans2') }}</div>
              <div class="line4" />
              <div class="ti">{{ $t('home.ask3') }}</div>
              <div class="text">{{ $t('home.ans3') }}</div>
              <div class="line4" />
              <div class="ti">{{ $t('home.ask4') }}</div>
              <div class="text">{{ $t('home.ans4') }}</div>
            </div>
          </div>
          <div outlined tile class="bgphone">
            <img class="size" src="../../assets/mobile/bgphone.png" alt="phone" srcset="../../assets/mobile/bgphone.png">
          </div>
        </div>
        <div class="img">
          <img class="size" src="../../assets/mobile/b3.png">
        </div>
        <div class="imgs">
          <img class="size" src="../../assets/mobile/b2.png">
        </div>
      </div>
    </div>

    <div class="section2-title">
      <div class="t mt-5">
        <div class="text px-3 py-1">{{ $t("home.t1") }}</div>
      </div>
    </div>

    <div class="section2 mt-3">
      <div>
        <div class="card pr-4 py-4 mb-2 d-flex align-center align-self-center justify-space-between">
          <div class="imgs">
            <img class="size" src="../../assets/mobile/se1.png" alt="" srcset="">
          </div>
          <div class="word">
            <div class="tt">
              {{ $t('home.tt1') }}
            </div>
            <div class="text">
              {{ $t('home.text1') }}
            </div>
          </div>
        </div>
        <div class="card pr-4 py-4 mb-2 d-flex align-center align-self-center justify-space-between">
          <div class="imgs">
            <img class="size" src="../../assets/mobile/se2.png" alt="" srcset="">
          </div>
          <div class="word">
            <div class="tt">
              {{ $t('home.tt2') }}
            </div>
            <div class="text">
              {{ $t('home.text2') }}
            </div>
          </div>
        </div>
        <div class="card pr-4 py-4 mb-2 d-flex align-center align-self-center justify-space-between">
          <div class="imgs">
            <img class="size" src="../../assets/mobile/se3.png" alt="" srcset="">
          </div>
          <div class="word">
            <div class="tt">
              {{ $t('home.tt3') }}
            </div>
            <div class="text">
              {{ $t('home.text3') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section2-title my-4">
      <div class="t my-2">
        <div class="text px-3 py-1">{{ $t("home.t2") }}</div>
      </div>
    </div>
    <div class="section3">
      <div class="card mb-2">
        <div class="cimgs">
          <div class="pic">
            <img class="size" src="../../assets/mobile/c1.png" alt="" srcset="">
          </div>
          <div class="star">
            <img class="size" src="../../assets/mobile/star.png" alt="">
          </div>
        </div>
        <div class="xt">
          <div class="ctt my-2">{{ $t('home.ctt1') }}</div>
          <div class="ctext">{{ $t('home.ctext1') }}</div>
        </div>
      </div>
      <div class="card mb-2">
        <div class="cimgs">
          <div class="pic">
            <img class="size" src="../../assets/mobile/c2.png" alt="" srcset="">
          </div>
          <div class="star">
            <img class="size" src="../../assets/mobile/star.png" alt="">
          </div>
        </div>
        <div class="xt">
          <div class="ctt my-2">{{ $t('home.ctt2') }}</div>
          <div class="ctext">{{ $t('home.ctext2') }}</div>
        </div>
      </div>
    </div>
    <div class="section4">
      <div class="title4">
        <div class="t mt-6 mb-2">
          <div class="text px-3 py-1">{{ $t("home.t3") }}</div>
        </div>
      </div>
      <div class="dmain">
        <div class="dws">
          <div class="dtt pb-1">{{ $t('home.dtt1') }}</div>
          <div class="dtext">{{ $t('home.dtext1') }}</div>
          <div class="dimgs d-flex align-center align-self-center justify-space-between pt-2">
            <div class="dimg"><a href="https://apps.apple.com/us/app/yumy-live-video-chat/id1542152757"><img class="size" src="../../assets/mobile/apple.png" alt=""></a></div>
            <div class="dimg"><a href="https://play.google.com/store/apps/details?id=com.yumy.live"><img class="size" src="../../assets/mobile/android.png" alt=""></a></div>
          </div>
          <div class="dimgs d-flex justify-center">
            <div class="cmiddle mt-n2">
              <img class="size" src="../../assets/mobile/m2.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Menu from '../../components/mobile/menu.vue'
import Umenu from '../../components/mobile/unmenu.vue'

export default {
  name: 'Home',
  components: {
    Menu,
    Umenu
  },
  data() {
    return {
      lang: this.$route.query.lang,
      showMenu: true
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandle, false)
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandle, true)
  },
  created() {
    this.autoSetLang()
    this.scrollHandle()
  },
  methods: {
    screenTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0
    },
    autoSetLang() {
      this.$i18n.locale = 'en'
      if (this.lang) {
        this.$i18n.locale = this.lang
      }
    },
    scrollHandle() {
      // const top = this.$refs.tops.getBoundingClientRect().top
      // if (top < 0) {
      //   // this.showMenu = false
      // } else {
      //   this.showMenu = true
      // }
    }
  }
}
</script>
<style lang="scss" scoped>

.section1 {
  height: 100%;
  background: linear-gradient(45deg, #F40076,#DD35FF);
  position: relative;
 .line5 {
   height: 10%;
 }
 .line4 {
   height: .4rem;
 }
 .sec{
   margin: 0 auto;
   width: 90%;
 }
 .h1 {
   font-family: Roboto, Roboto-Black;
   font-size: 28px;
   font-weight: 900;
   color: #131415;
   line-height: 36px;
   background: #fff;
 }
 .word {
   .ti {
     font-size: 18px;
     font-family: Roboto, Roboto-Bold;
     font-weight: 700;
     color: #ffffff;
     line-height: 24px;
   }
   .text{
     font-size: 14px;
     font-family: Roboto, Roboto-Bold;
     font-weight: 500;
     color: #ffffff;
     line-height: 18px;
   }
 }
 .bgphone {
   width: 70%;
   margin: 0 auto;
   .size {
     width: 100%;
   }
 }
 .img {
   width: 10%;
   position: absolute;
   bottom: 4%;
   left: -5%;
   .size {
     width: 100%;
   }
 }
 .imgs {
   width: 15%;
   position: absolute;
   bottom: 8%;
   right: 0;
   .size {
     width: 100%;
   }
 }
}
.section2-title {
  margin: 0 auto;
  .t {
    .text {
      font-size: 20px;
      font-family: Roboto, Roboto-Black;
      font-weight: 900;
      text-align: left;
      color: #ffffff;
      line-height: 26px;
      letter-spacing: -1px;
      background-color: #E327DD;
    }
  }
}
.section2 {
  margin: 0 auto;
  width: 90%;
  .card {
    border: 1px solid #fafafa;
    box-shadow: 0px 3px 1px 1px rgb(0 0 0 / 3%), 0px 0px 0px 0px rgb(0 0 0 / 10%), 1px 1px 2px 0px rgb(0 0 0 / 8%);
    border-radius: 6px;
    .imgs{
      margin: 0 auto;
      width: 25%;
      .size {
        width: 100%;
      }
    }
    .word {
      width: 70%;
      .tt {
        font-size: 18px;
        font-family: Roboto, Roboto-Black;
        font-weight: 900;
        color: #182639;
        line-height: 24px;
      }
      .text {
        margin: 0 auto;
        font-size: 12px;
        font-family: Roboto, Roboto-Medium;
        font-weight: 400;
        color: #9dabbb;
        line-height: 16px;
      }
    }
  }

}
.section3 {
  margin: 0 auto;
  width: 90%;
 .card {
   .cimgs {
     position: relative;
    .pic {
      .size {
        width: 100%;
      }
    }
    .star {
      width: 33%;
      position: absolute;
      bottom: -.3rem;
      right: -.3rem;
      .size {
        width: 100%;
      }
    }
   }
 }
 .xt {
   .ctt {
     margin: 0 .2rem;
     font-family: Roboto, Roboto-Black;
     font-weight: 900;
     text-align: left;
     color: #182639;
     line-height: 24px;
     font-size: 18px;
   }
   .ctext {
     margin: 0 .2rem;
     font-family: Roboto, Roboto-Medium;
     font-weight: 400;
     text-align: justify;
     color: #9dabbb;
     line-height: 16px;
     font-size: 12px;
   }
 }

}
.section4 {
  background-color: #F2F7F8;
  .title4 {
    margin: 0 auto;
    width: fit-content;
    .t {
      .text {
        font-size: 24px;
        font-family: Roboto, Roboto-Black;
        font-weight: 900;
        text-align: left;
        color: #ffffff;
        line-height: 34px;
        letter-spacing: -1px;
        background-color: #E327DD;
      }
    }
  }
  .dmain {
    .dws {
      margin:0 auto;
      padding-bottom: 8%;
      width: 90%;
      .dtt {
        font-size: 18px;
        font-family: Roboto, Roboto-Black;
        font-weight: 900;
        color: #131415;
        line-height: 24px;
        letter-spacing: -1px;
      }
      .dtext {
        font-size: 12px;
        font-family: Roboto, Roboto-Medium;
        font-weight: 400;
        text-align: justify;
        color: #9dabbb;
        line-height: 16px;
      }
      .dimgs {
        width: 90%;
        margin: 0 auto;
        .dimg {
          width: 48%;
          .size {
            width: 100%;
          }
        }
      }
    }
    .cmiddle {
      margin: 0 auto;
      text-align: center;
      .size {
        width: 30%;
      }
    }
  }
}
</style>
