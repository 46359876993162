<template>
  <v-app>
    <div class="all">
      <div class="menu">
        <Unmenu />
      </div>
      <div class="pmain mt-16 pt-10">
        <div class="rows">
          <div class="d-flex align-center align-self-center justify-space-between my-16">
            <div class="word">
              <div class="th1">
                <img class="size" src="../assets/yaya.png" alt="">
              </div>
              <div class="app mt-n14 mb-6 d-flex align-center align-self-center justify-space-between">
                <div class="icon"><img class="size" src="../assets/app.png" alt=""></div>
                <router-link to="/contactus">
                  <div class="btn px-8 d-flex align-center align-self-center justify-center">
                    <img class="size" src="../assets/add.png" alt="">
                    <div class="tt ml-4">{{ $t('about.tt1') }}</div>
                  </div>
                </router-link>
              </div>
              <div>
                <div class="pic">
                  <img class="size" src="../assets/yayaphone.png" alt="">
                </div>
                <div class="ask mb-1">{{ $t("yaya.ask1") }}</div>
                <div class="ans mb-3">{{ $t("yaya.ans11") }}</div>
                <div class="ans mb-3">{{ $t("yaya.ans12") }}</div>
                <div class="ask">{{ $t("yaya.ask2") }}</div>
                <div class="ans mb-3">{{ $t("yaya.ans21") }}</div>
                <div class="ans mb-3">
                  {{ $t("yaya.ans221") }}
                  <span class="redtext">{{ $t("yaya.ans222") }}</span>
                  {{ $t("yaya.ans223") }}
                  <span class="redtext">{{ $t("yaya.ans224") }}</span>
                  {{ $t("yaya.ans225") }}
                  <span class="redtext">{{ $t("yaya.ans226") }}</span>
                  {{ $t("yaya.ans227") }}
                  <span class="redtext">{{ $t("yaya.ans228") }}</span>
                  {{ $t("yaya.ans229") }}
                  <span class="redtext">{{ $t("yaya.ans2210") }}</span>
                  {{ $t("yaya.ans2211") }}
                </div>
                <div class="ans mb4 redline ">{{ $t("yaya.ans23") }}</div>

                <div class="ask">{{ $t("yaya.ask3") }}</div>
                <div class="ans mb-6">{{ $t("yaya.ans31") }}</div>
                <div class="list mt-2 mb-3 d-flex align-center align-self-center"><img class="size mr-4" src="../assets/heart.png" alt=""><div>{{ $t('yaya.ans321') }}</div></div>
                <div class="list mt-2 mb-3 d-flex align-center align-self-center"><img class="size mr-4" src="../assets/heart.png" alt=""><div>{{ $t('yaya.ans322') }}</div></div>
                <div class="list mt-2 mb-3 d-flex align-center align-self-center"><img class="size mr-4" src="../assets/heart.png" alt=""><div>{{ $t('yaya.ans323') }}</div></div>
                <div class="list mt-2 mb-3 d-flex align-center align-self-center"><img class="size mr-4" src="../assets/heart.png" alt=""><div>{{ $t('yaya.ans324') }}</div></div>
                <div class="list mt-2 mb-3 d-flex align-center align-self-center"><img class="size mr-4" src="../assets/heart.png" alt=""><div>{{ $t('yaya.ans325') }}</div></div>
                <div class="list mt-2 mb-3 d-flex align-center align-self-center"><img class="size mr-4" src="../assets/heart.png" alt=""><div>{{ $t('yaya.ans326') }}</div></div>
                <div class="list mt-2 mb-3 d-flex align-center align-self-center"><img class="size mr-4" src="../assets/heart.png" alt=""><div>{{ $t('yaya.ans327') }}</div></div>
                <div class="list mt-2 mb-3 d-flex align-center align-self-center"><img class="size mr-4" src="../assets/heart.png" alt=""><div>{{ $t('yaya.ans328') }}</div></div>
                <div class="list mt-2 mb-3 d-flex align-center align-self-center"><img class="size mr-4" src="../assets/heart.png" alt=""><div>{{ $t('yaya.ans329') }}</div></div>
                <div class="list mt-2 mb-3 d-flex align-center align-self-center"><img class="size mr-4" src="../assets/heart.png" alt=""><div>{{ $t('yaya.ans3210') }}</div></div>

                <div class="ask">{{ $t("yaya.ask4") }}</div>
                <div class="ans">{{ $t("yaya.ans41") }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="pub1">
          <img src="../assets/pub1.png" class="size">
        </div>
        <div class="pub2">
          <img src="../assets/pub2.png" class="size">
        </div>
        <div class="pub3">
          <img src="../assets/pub3.png" class="size">
        </div>
      </div>
      <Price />
    </div>
  </v-app>
</template>
<script>
import Unmenu from '../components/unmenu.vue'
import Price from '../components/price.vue'
export default {
  components: {
    Unmenu,
    Price
  }
}
</script>

<style lang="scss" scoped>
.pmain {
  z-index: 1;
  position: relative;
  .rows {
    width: 80%;
    margin: 0 auto;
    .word {
      .th1 {
        margin-left: -5%;
        width: 75%;
        .size {
          width: 100%;
        }
      }
      .app {
        width: 40%;
        .icon {
          width: 40%;
          .size {
            width: 100%;
          }
        }
      }
      .btn {
        width:-webkit-fit-content;
        width:-moz-fit-content;
        width:fit-content;
        height: 80px;
        background: #5127E3;
        border-radius: 40px;
        margin: 0 auto;
        cursor: pointer;
        .size {
          width: 38px;
          height: 44px;
        }
        .tt{
          font-size: 36px;
          font-family: Roboto, Roboto-Black;
          font-weight: 900;
          color: #ffffff;
          line-height: 80px;
          height: 80px;
        }
      }
      .pic {
      width: 25%;
      float: right;
      clear: both;
      margin-top: -15%;
      .size {
        width: 100%;
      }
    }
      .ans{
        font-size: 18px;
        font-family: Roboto, Roboto-Medium;
        font-weight: 500;
        color: #182639;
        line-height: 24px;
      }
      .ask {
        font-size: 36px;
        font-family: Roboto, Roboto-Black;
        font-weight: 900;
        color: #131415;
        line-height: 58px;
        letter-spacing: -1px;
      }
      .redtext {
        color: #ff0300;
      }
      .redline {
        color: #ff0300;
        text-decoration: underline;
      }
      .list {
        font-size: 18px;
        font-family: Roboto, Roboto-Bold;
        font-weight: 700;
        text-align: justify;
        color: #ff0300;
        line-height: 24px;
        .size {
          width: 40px;
          vertical-align: middle;
        }
      }
    }
  }
  .pub1 {
    position: absolute;
    bottom: 0;
    left: 0;
    .size{
      width: 30%;
    }
  }
  .pub2 {
    position: absolute;
    bottom: 5rem;
    right: 1rem;
    .size{
      width: 80px;
    }
  }
  .pub3 {
    position: absolute;
    top: 1rem;
    right: 0;
    .size{
      width: 156px;
    }
  }
}
</style>
