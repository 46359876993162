<template>
  <v-app>
    <Unmenu />
    <div class="main mt-8">
      <div class="rows">
        <div class="mb-6">
          <div class="pic">
            <img class="size" src="../../assets/mobile/email.png" alt="">
            <div class="h1">Email :</div>
            <div class="redtext">{{ $t("contact.email") }}</div>
          </div>
          <div class="pic">
            <img class="size" src="../../assets/mobile/whatsapp.png" alt="">
            <div class="h1">WhatsApp :</div>
            <div class="redtext">{{ $t("contact.phone1") }}</div>
            <div class="redtext">{{ $t("contact.phone2") }}</div>
          </div>
        </div>
      </div>
      <div class="pub1">
        <img src="../../assets/mobile/b3.png" class="size">
      </div>
      <div class="pub2">
        <img src="../../assets/mobile/b4.png" class="size">
      </div>
      <div class="pub3">
        <img src="../../assets/mobile/b3.png" class="size">
      </div>
    </div>
    <div class="foot mt-16 mb-4">
      <div class="footext">{{ $t("contact.info") }}</div>
    </div>
  </v-app>
</template>
<script>
import Unmenu from '../../components/mobile/unmenu.vue'
export default {
  components: {
    Unmenu
  }
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  .rows {
    width: 90%;
    margin: 0 auto;
    .pic {
      text-align: center;
      width: 60%;
      margin: 0 auto;
      .size {
        width: 100%;
      }
      .h1 {
        font-size: 20px;
        font-family: Roboto, Roboto-Black;
        font-weight: 900;
        text-align: center;
        color: #182639;
        line-height: 26px;
      }
      .redtext {
        font-size: 14px;
        font-family: Roboto, Roboto-Bold;
        font-weight: 400;
        text-align: center;
        color: #ff0300;
        line-height: 20px;
      }
    }
  }
  .pub1 {
    width: 10%;
    position: absolute;
    bottom: 0;
    left: -5%;
    .size{
      width: 100%;
    }
  }
  .pub2 {
    width: 12%;
    position: absolute;
    top: 22%;
    right: 0;
    .size{
      width: 100%;
    }
  }
  .pub3 {
    width: 10%;
    position: absolute;
    top: 4rem;
    left: -5%;
    .size{
      width: 100%;
    }
  }
}
  .foot {
    margin: 0 auto;
    .footext {
      font-size: 14px;
      font-family: Roboto, Roboto-Medium;
      font-weight: 500;
      color: #9dabbb;
      line-height: 20px;
      text-align: center;
    }
  }
</style>
