<template>
  <div class="menu">
    <div class="nav">
      <div
        class="d-flex mb-4"
        color="grey lighten-2"
      >
        <div class="mr-auto pt-2 px-2 imgs" outlined tile>
          <router-link to="/"><img class="size" alt="logo" src="../assets/logo.png"></router-link>
        </div>
        <div class="px-5 pt-8" outlined tile>
          <router-link :class="[name==='Home'?'show':'']" to="/">{{ $t("menu.text1") }}</router-link>
        </div>
        <div class="px-5 pt-8 text" outlined tile>
          <router-link :class="[name==='About'?'show':'']" to="/about">{{ $t("menu.text2") }}</router-link>
        </div>
        <div class="px-5 pt-8 text" outlined tile>
          <router-link :class="[name==='YumyYaya'?'show':'']" to="/product">{{ $t("menu.text3") }}</router-link>
        </div>
        <div class="px-5 pt-8 text" outlined tile>
          <router-link :class="[name==='management'?'show':'']" to="/management">{{ $t("menu.text4") }}</router-link>
        </div>
        <div class="px-5 pt-8 text" outlined tile>
          <router-link :class="[name==='contactUs'?'show':'']" to="/contactus">{{ $t("menu.text5") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

  data() {
    return {
      name: ''
    }
  },
  created() {
    this.getRouteName()
  },
  mounted() {
    this.getRouteName()
  },
  methods: {
    getRouteName() {
      this.name = this.$route.name
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}
.nav {
  padding: 0.5% 5%;
	box-shadow: 0px 6px 4px 0px rgba(0, 0, 0, .03);
  position: static;
  background: #fff;
  z-index: 9999;
	.imgs {
		height: 4.875rem;
	}
	.size {
		height: 100%;
  }
  a {
		font-size: 1.3rem;
		font-family: Roboto, Roboto-Medium;
		font-weight: 500;
		text-align: left;
		color: #9DABBB;
		line-height: 2rem;
		text-decoration: none;
  }
	.show {
      color: #fff;
			background: #E327DD;
			border-radius: 27px;
			padding: 10px 20px;
      font-weight: 900;
    }
}
</style>
