<template>
  <v-app>
    <Unmenu />
    <div class="main mt-16 pt-10">
      <div class="rows">
        <div class="d-flex align-center align-self-center justify-space-between my-16">
          <div class="word">
            <div class="th1">
              <img class="size" src="../assets/management.png" alt="">
            </div>
            <div class="app mt-n14 mb-6 d-flex align-center align-self-center justify-space-between">
              <div class="icon"><img class="size" src="../assets/app.png" alt=""></div>
              <router-link to="/contactus">
                <div class="btn px-8 d-flex align-center align-self-center justify-center">
                  <img class="size" src="../assets/add.png" alt="">
                  <div class="tt ml-4">{{ $t('about.tt1') }}</div>
                </div>
              </router-link>
            </div>
            <div class="text">{{ $t('management.text1') }}</div>
            <div class="h1 my-6">{{ $t('management.tt1') }}</div>
            <div class="list my-2"><img class="size mr-4" src="../assets/heart.png" alt="">{{ $t('management.list1') }}</div>
            <div class="list my-2"><img class="size mr-4" src="../assets/heart.png" alt="">{{ $t('management.list2') }}</div>
            <div class="list my-2"><img class="size mr-4" src="../assets/heart.png" alt="">{{ $t('management.list3') }}</div>
            <div class="h1 my-6">{{ $t('management.tt2') }}</div>
            <div class="text">{{ $t('management.text2') }}</div>
          </div>
          <div class="pic">
            <img class="size" src="../assets/managphone.png" alt="">
          </div>
        </div>
      </div>
      <div class="pub1">
        <img src="../assets/pub1.png" class="size">
      </div>
      <div class="pub2">
        <img src="../assets/pub2.png" class="size">
      </div>
      <div class="pub3">
        <img src="../assets/pub3.png" class="size">
      </div>
    </div>
    <Price />
  </v-app>
</template>
<script>
import Unmenu from '../components/unmenu.vue'
import Price from '../components/price.vue'
export default {
  components: {
    Unmenu,
    Price
  }
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  .rows {
    width: 80%;
    margin: 0 auto;
    .word {
      width: 65%;
      .th1 {
        margin-left: -15%;
        width: 90%;
        .size {
          width: 100%;
        }
      }
      .app {
        width: 60%;
        .icon {
          width: 40%;
          .size {
            width: 100%;
          }
        }
      }
      .btn {
        width:-webkit-fit-content;
        width:-moz-fit-content;
        width:fit-content;
        height: 80px;
        background: #5127E3;
        border-radius: 40px;
        margin: 0 auto;
        cursor: pointer;
        .size {
          width: 38px;
          height: 44px;
        }
        .tt{
          font-size: 36px;
          font-family: Roboto, Roboto-Black;
          font-weight: 900;
          color: #ffffff;
          line-height: 80px;
          height: 80px;
        }
      }
      .text{
        font-size: 18px;
        font-family: Roboto, Roboto-Medium;
        font-weight: 500;
        color: #182639;
        line-height: 24px;
      }
      .h1 {
        font-size: 36px;
        font-family: Roboto, Roboto-Black;
        font-weight: 900;
        text-align: left;
        color: #131415;
        line-height: 58px;
        letter-spacing: -1px;
      }
      .list {
        font-size: 18px;
        font-family: Roboto, Roboto-Bold;
        font-weight: 700;
        text-align: justify;
        color: #ff0300;
        line-height: 24px;
        .size {
          width: 50px;
          vertical-align: middle;
        }
      }
    }
    .pic {
      width: 25%;
      .size {
        width: 100%;
      }
    }
  }
  .pub1 {
    position: absolute;
    bottom: 0;
    left: 0;
    .size{
      width: 30%;
    }
  }
  .pub2 {
    position: absolute;
    bottom: 5rem;
    right: 1rem;
    .size{
      width: 80px;
    }
  }
  .pub3 {
    position: absolute;
    top: 1rem;
    right: 0;
    .size{
      width: 156px;
    }
  }
}
</style>
