<template>
  <v-app class="index">
    <div ref="tops" class=" section1">
      <div class="menu">
        <Menu v-if="showMenu" />
        <Umenu v-else />
      </div>
      <div class="sec mt-n8">
        <div
          class="d-flex align-center align-self-center justify-space-between"
          flat
          tile
        >
          <div class="mt-n10">
            <div class="h1 pa-2 pb-4">{{ $t('home.h1') }}</div>
            <div class="word">
              <div class="line4" />
              <div class="ti">{{ $t('home.ask1') }}</div>
              <div class="text">{{ $t('home.ans1') }}</div>
              <div class="line4" />
              <div class="ti">{{ $t('home.ask2') }}</div>
              <div class="text">{{ $t('home.ans2') }}</div>
              <div class="line4" />
              <div class="ti">{{ $t('home.ask3') }}</div>
              <div class="text">{{ $t('home.ans3') }}</div>
              <div class="line4" />
              <div class="ti">{{ $t('home.ask4') }}</div>
              <div class="text">{{ $t('home.ans4') }}</div>
            </div>
          </div>
          <div class="bgphone">
            <img class="size" src="../assets/bgphone.png" alt="phone" srcset="../assets/bgphone.png">
          </div>
        </div>
        <div class="img mt-n16">
          <v-img lazy-src="../assets/b3.png" max-height="98" max-width="98" src="../assets/b3.png" />
        </div>
      </div>
      <div class="imgs">
        <img class="size" src="../assets/b2.png">
      </div>
    </div>
    <div class="section2-title">
      <div class="t my-12">
        <div class="text px-4 py-2">{{ $t("home.t1") }}</div>
      </div>
    </div>

    <div class="section2 mb-10">
      <div class="d-flex justify-space-between mb-6" flat tile>
        <div class="card">
          <div class="imgs">
            <img class="size" src="../assets/se1.png" alt="" srcset="">
          </div>
          <div class="tt my-2">
            {{ $t('home.tt1') }}
          </div>
          <div class="text mb-6">
            {{ $t('home.text1') }}
          </div>
        </div>
        <div class="card">
          <div class="imgs">
            <img class="size" src="../assets/se2.png" alt="" srcset="">
          </div>
          <div class="tt my-2">
            {{ $t('home.tt2') }}
          </div>
          <div class="text mb-6">
            {{ $t('home.text2') }}
          </div>
        </div>
        <div class="card">
          <div class="imgs">
            <img class="size" src="../assets/se3.png" alt="" srcset="">
          </div>
          <div class="tt my-2">
            {{ $t('home.tt3') }}
          </div>
          <div class="text mb-6">
            {{ $t('home.text3') }}
          </div>
        </div>
      </div>

    </div>
    <div class="section3 my-16">
      <div class="d-flex align-center align-self-center justify-space-between mb-6" flat>
        <div class="card">
          <div class="cimgs">
            <div class="pic">
              <img class="size" src="../assets/c1.png" alt="" srcset="">
            </div>
            <div class="star">
              <img class="size" src="../assets/star.png" alt="">
            </div>
          </div>
          <div class="xt">
            <div class="ctt my-3">{{ $t('home.ctt1') }}</div>
            <div class="ctext">{{ $t('home.ctext1') }}</div>
          </div>
        </div>
        <div class="middle">
          <img class="size" src="../assets/m1.png" alt="">
        </div>
        <div class="card">
          <div class="cimgs">
            <div class="pic">
              <img class="size" src="../assets/c2.png" alt="" srcset="">
            </div>
            <div class="star">
              <img class="size" src="../assets/star.png" alt="">
            </div>
          </div>
          <div class="xt">
            <div class="ctt my-3">{{ $t('home.ctt2') }}</div>
            <div class="ctext">{{ $t('home.ctext2') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section4">
      <div class="dmain">
        <div class="dws">
          <div class="dtt pb-2">{{ $t('home.dtt1') }}</div>
          <div class="dtext">{{ $t('home.dtext1') }}</div>
          <div class="dimgs d-flex align-center align-self-center justify-space-between pt-6">
            <div class="dimg"><a href="https://apps.apple.com/us/app/yumy-live-video-chat/id1542152757"><img class="size" src="../assets/apple.png" alt=""></a></div>
            <div class="dimg"><a href="https://play.google.com/store/apps/details?id=com.yumy.live"><img class="size" src="../assets/android.png" alt=""></a></div>
          </div>
          <div class="dimgs d-flex justify-center">
            <div class="cmiddle">
              <img class="size" src="../assets/m2.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Menu from '../components/menu.vue'
import Umenu from '../components/unmenu.vue'

export default {
  name: 'Home',
  components: {
    Menu,
    Umenu
  },
  data() {
    return {
      lang: this.$route.query.lang,
      showMenu: true
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandle, false)
  },
  mounted() {
    this.screenTop()
    window.addEventListener('scroll', this.scrollHandle, true)
  },
  created() {
    this.autoSetLang()
    this.scrollHandle()
    this.screenTop()
  },
  methods: {
    screenTop() {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    },
    autoSetLang() {
      this.$i18n.locale = 'en'
      if (this.lang) {
        this.$i18n.locale = this.lang
      }
    },
    scrollHandle() {
      const top = this.$refs.tops.getBoundingClientRect().top
      if (top < 0) {
        this.showMenu = false
      } else {
        this.showMenu = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.section1 {
  height: 100%;
  background: linear-gradient(to right, #F40076,#DD35FF);
  position: relative;
 .line5 {
   height: 10%;
 }
 .line4 {
   height: 1rem;
 }
 .sec{
   width: 90%;
   margin: 0 auto;
 }
 .h1 {
   margin-left: 1.5rem;
   font-size: 3.75rem;
   font-family: Roboto, Roboto-Black;
   font-weight: 900;
   text-align: left;
   color: #131415;
   line-height: 5rem;
   letter-spacing: -1px ;
   background: #fff;
 }
 .word {
   margin-left: 1.5rem;
   .ti {
     font-size: 2rem;
     font-family: Roboto, Roboto-Bold;
     font-weight: 700;
     text-align: left;
     color: #ffffff;
     line-height: 2.8rem;
   }
   .text{
     font-size: 20px;
     font-family: Roboto, Roboto-Bold;
     font-weight: 500;
     color: #ffffff;
     line-height: 1.2rem;
   }
 }
 .bgphone {
   width: 40%;
   .size {
     width: 100%;
   }
 }
 .imgs {
   width: 194px;
   position: absolute;
   bottom: 0;
   right: 0;
   .size {
     width: 100%;
   }
 }
}
.section2-title {
  margin: 0 auto;
  .t {
    .text {
      font-size: 36px;
      font-family: Roboto, Roboto-Black;
      font-weight: 900;
      text-align: left;
      color: #ffffff;
      line-height: 58px;
      letter-spacing: -1px;
      background-color: #E327DD;
    }
  }
}
.section2 {
  margin: 0 auto;
  width: 80%;
  .card {
    width: 31%;
    border: 1px solid #fafafa;
    box-shadow: 0px 3px 1px 1px rgb(0 0 0 / 3%), 0px 0px 0px 0px rgb(0 0 0 / 10%), 1px 1px 2px 0px rgb(0 0 0 / 8%);
    border-radius: 6px;
    .imgs{
      width: 50%;
      margin: 0 auto;
      .size {
        width: 100%;
      }
    }
    .tt {
      text-align: center;
      font-size: 36px;
      font-family: Roboto, Roboto-Black;
      font-weight: 900;
      text-align: center;
      color: #182639;
      line-height: 40px;
    }
    .text {
      margin: 0 auto;
      width: 90%;
      font-size: 18px;
      font-family: Roboto, Roboto-Medium;
      font-weight: 500;
      text-align: center;
      color: #9dabbb;
      line-height: 24px;
    }
  }

}
.section3 {
  margin: 0 auto;
  width: 80%;
 .card {
   width: 42%;
   .cimgs {
     position: relative;
    .pic {
      .size {
        width: 100%;
      }
    }
    .star {
      width: 30%;
      position: absolute;
      bottom: -1rem;
      right: -1.5rem;
      .size {
        width: 100%;
      }
    }
   }
 }
 .middle {
   width: 3%;
   margin-top: -10%;
   .size {
     width: 100%;
   }
 }
 .xt {
   min-height: 8rem;
   .ctt {
     margin: 0 .2rem;
     font-family: Roboto, Roboto-Black;
     font-weight: 900;
     text-align: left;
     color: #182639;
     line-height: 40px;
     font-size: 36px;
   }
   .ctext {
     margin: 0 .2rem;
     font-family: Roboto, Roboto-Medium;
     font-weight: 500;
     text-align: left;
     color: #9dabbb;
     line-height: 24px;
     font-size: 18px;
   }
 }

}
.section4 {
  background-color: #F2F7F8;
  .dmain {
    height: 100%;
    background: no-repeat  top right/60% url("../assets/dbg.png");
    .dws {
      margin:0 auto;
      padding-top: 8%;
      padding-bottom: 8%;
      height: 100%;
      line-height: 100%;
      width: 80%;
      vertical-align: middle;
      .dtt {
        width: 53%;
        font-size: 48px;
        font-family: Roboto, Roboto-Black;
        font-weight: 900;
        text-align: left;
        color: #131415;
        line-height: 63px;
        letter-spacing: -1px;
      }
      .dtext {
        width: 50%;
        font-size: 18px;
        font-family: Roboto, Roboto-Medium;
        font-weight: 500;
        text-align: left;
        color: #9dabbb;
        line-height: 24px;
      }
      .dimgs {
        width: 30%;
        .dimg {
          width: 48%;
          .size {
            width: 100%;
          }
        }
      }
    }
    .cmiddle {
      margin: 0 auto;
      text-align: center;
      .size {
        width: 50%;
      }
    }
  }
}
</style>
