<template>
  <v-app>
    <Unmenu />
    <div class="main mt-8">
      <div class="rows">
        <div class="mb-4">
          <div class="word">
            <div class="th1">
              <img class="size" src="../../assets/mobile/management.png" alt="">
            </div>
            <div class="app mt-n4 mb-2 d-flex align-center align-self-center justify-space-between">
              <div class="icon"><img class="size" src="../../assets/mobile/app.png" alt=""></div>
              <div class="mid" />
              <router-link to="/contactus">
                <div class="btn px-4 d-flex align-center align-self-center justify-space-between">
                  <img class="size" src="../../assets/mobile/add.png" alt="">
                  <div class="tt ml-2">{{ $t('about.tt1') }}</div>
                </div>
              </router-link>
              <div class="mid2" />
            </div>
            <div class="text mb-2">{{ $t('management.text1') }}</div>
            <div class="pic">
              <img class="size" src="../../assets/mobile/managphone.png" alt="">
            </div>
            <div class="h1 my-2">{{ $t('management.tt1') }}</div>
            <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div class="listext">{{ $t('management.list1') }}</div></div>
            <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div class="listext">{{ $t('management.list2') }}</div></div>
            <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div class="listext">{{ $t('management.list3') }}</div></div>
            <div class="h1 my-1">{{ $t('management.tt2') }}</div>
            <div class="text">{{ $t('management.text2') }}</div>
          </div>
        </div>
      </div>
      <div class="pub1">
        <img src="../../assets/mobile/b3.png" class="size">
      </div>
      <div class="pub2">
        <img src="../../assets/mobile/b4.png" class="size">
      </div>
      <div class="pub3">
        <img src="../../assets/mobile/b3.png" class="size">
      </div>
    </div>
    <Price />
  </v-app>
</template>
<script>
import Unmenu from '../../components/mobile/unmenu.vue'
import Price from '../../components/mobile/price.vue'
export default {
  components: {
    Unmenu,
    Price
  }
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  .rows {
    width: 90%;
    margin: 0 auto;
    .word {
      .th1 {
        margin-left: -1%;
        width: 90%;
        .size {
          width: 100%;
        }
      }
      .app {
        .icon {
          width: 40%;
          .size {
            width: 100%;
          }
        }
        .mid {
          width: 8%;
        }
        .mid2 {
          width: 2%;
        }
        .btn {
          width:-webkit-fit-content;
          width:-moz-fit-content;
          width:fit-content;
          height: 40px;
          background: #5127E3;
          border-radius: 40px;
          margin: 0 auto;
          cursor: pointer;
          .size {
            width: 18px;
            height: 24px;
          }
          .tt{
            font-size: 18px;
            font-family: Roboto, Roboto-Black;
            font-weight: 900;
            color: #ffffff;
            line-height:24px;
            height: 24px;
          }
        }
      }
      .text{
        font-size: 14px;
        font-family: Roboto, Roboto-Medium;
        font-weight: 400;
        color: #182639;
        line-height: 20px;
        text-align: justify;
      }
      .h1 {
        font-size: 20px;
        font-family: Roboto, Roboto-Black;
        font-weight: 900;
        text-align: left;
        color: #131415;
        line-height: 26px;
      }
      .list {
        font-size: 14px;
        font-family: Roboto, Roboto-Bold;
        font-weight: 500;
        color: #ff0300;
        line-height: 20px;
        text-align: justify;
        .size {
          width: 1.2rem;
          vertical-align: middle;
        }
      }
    }
    .pic {
      width: 100%;
      margin: 0 auto;
      .size {
        width: 100%;
      }
    }
  }
  .pub1 {
    width: 10%;
    position: absolute;
    top: 50%;
    left: -5%;
    .size{
      width: 100%;
    }
  }
  .pub2 {
    width: 12%;
    position: absolute;
    top: 3rem;
    right: 0;
    .size{
      width: 100%;
    }
  }
  .pub3 {
    width: 10%;
    position: absolute;
    top: -1rem;
    left: -5%;
    .size{
      width: 100%;
    }
  }
}
</style>
