import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import About from '../views/aboutme.vue'
import YumyYaya from '../views/yumyyaya.vue'
import contactUs from '../views/contactus.vue'
import management from '../views/management.vue'

import { isMobileFunc } from '../utils'

import Mhome from '../views/mobile/home.vue'
import Mabout from '../views/mobile/aboutme.vue'
import Myumy from '../views/mobile/yumyyaya.vue'
import McontactUs from '../views/mobile/contactus.vue'
import Mmanagement from '../views/mobile/management.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    props: (route) => ({
      lang: route.query.lang
    }),
    component: isMobileFunc() ? Mhome : Home
  },
  {
    path: '/product',
    name: 'YumyYaya',
    component: isMobileFunc() ? Myumy : YumyYaya
  },
  {
    path: '/contactus',
    name: 'contactUs',
    component: isMobileFunc() ? McontactUs : contactUs
  },
  {
    path: '/management',
    name: 'management',
    component: isMobileFunc() ? Mmanagement : management
  },
  {
    path: '/about',
    name: 'About',
    component: isMobileFunc() ? Mabout : About
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
