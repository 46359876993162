<template>
  <div class="nav">
    <div
      class="d-flex justify-space-between align-self-center align-center mb-2"
      color="grey lighten-2"
    >
      <div class="pt-4 imgs" outlined tile>
        <img class="size" alt="logo" src="../../assets/mobile/logo.png">
      </div>
      <div class="menu">
        <v-dialog v-model="dialog" content-class="top-menu" transition="dialog-top-transition" max-width="100%">
          <template v-slot:activator="{ on, attrs }">
            <img v-bind="attrs" class="size mt-2" src="../../assets/mobile/bmenu.png" alt="menu" v-on="on">
          </template>
          <div class="lists mt-6 mb-6">
            <div class="close" @click="dialog=false">
              <img class="size" src="../../assets/mobile/close.png" alt="menu">
            </div>
            <div class="menus">
              <div class="item py-2 my-1" :class="[name==='Home'?'acolor':'']">
                <router-link :class="[name==='Home'?'wcolor':'bcolor']" class="itext" to="/">{{ $t('menu.text1') }}</router-link>
              </div>
              <div class="item py-2 my-1" :class="[name==='About'?'acolor':'']">
                <router-link :class="[name==='About'?'wcolor':'bcolor']" class="itext" to="/about">{{ $t('menu.text2') }}</router-link>
              </div>
              <div class="item py-2 my-1" :class="[name==='YumyYaya'?'acolor':'']">
                <router-link :class="[name==='YumyYaya'?'wcolor':'bcolor']" class="itext" to="/product">{{ $t('menu.text3') }}</router-link>
              </div>
              <div class="item py-2 my-1" :class="[name==='management'?'acolor':'']">
                <router-link :class="[name==='management'?'wcolor':'bcolor']" class="itext" to="/management">{{ $t('menu.text4') }}</router-link>
              </div>
              <div class="item py-2 my-1" :class="[name==='contactUs'?'acolor':'']">
                <router-link :class="[name==='contactUs'?'wcolor':'bcolor']" class="itext" to="/contactus">{{ $t('menu.text5') }}</router-link>
              </div>
            </div>
          </div>
        </v-dialog>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  data: () => ({
    dialog: false,
    name: ''
  }),
  created() {
    this.getRouteName()
  },
  mounted() {
    this.getRouteName()
  },
  methods: {
    getRouteName() {
      console.log('objec1111t', this.$route.name)
      this.name = this.$route.name
    }
  }
}
</script>
<style>
.top-menu {
  align-self: flex-start !important;
  margin: 0 !important;
  border-radius: 0 !important;
  border-bottom-left-radius: 6px!important;
  border-bottom-right-radius: 6px!important;
  background: #fff !important;
}
</style>
<style lang="scss" scoped>
.lists {
  width: 90%;
  margin: 0 auto;
  .close {
    text-align: right;
    .size {
      width: 22px;
    }
  }
  .menus {
    width: 90%;
    margin: 0 auto;
    .item {
      width: 60%;
      margin: 0 auto;
      text-align: center;
      .bcolor {
        font-size: 16px;
        font-family: Roboto, Roboto-Regular;
        font-weight: 400;
        text-align: center;
        color: #8d8b94;
        line-height: 21px;
      }
      .wcolor {
        color: #ffffff;
        font-size: 18px;
        font-family: Roboto, Roboto-Bold;
        text-align: center;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
}
.acolor {
  background: #182639;
}

.nav {
  padding: 0.5% 2%;
  background: #fff;
  box-shadow: 0px 6px 4px 0px rgba(0, 0, 0, .05);
	.imgs {
		width: 50%;
    .size {
      width: 100%;
    }
	}
  .menu {
    width: 10%;
    .size {
      width: 100%;
      color: #000;
    }
  }
}
</style>
