<template>
  <v-app>
    <div class="all">
      <Unmenu />
      <div class="pmain mt-8">
        <div class="rows mb-4">
          <div class="word">
            <div class="th1">
              <img class="size" src="../../assets/mobile/yaya.png" alt="">
            </div>
            <div class="app mt-n4 mb-2 d-flex align-center align-self-center justify-space-between">
              <div class="icon"><img class="size" src="../../assets/mobile/app.png" alt=""></div>
              <div class="mid" />
              <router-link to="/contactus">
                <div class="btn px-4 d-flex align-center align-self-center justify-space-between">
                  <img class="size" src="../../assets/mobile/add.png" alt="">
                  <div class="tt ml-2">{{ $t('about.tt1') }}</div>
                </div>
              </router-link>
              <div class="mid2" />
            </div>
            <div>
              <div class="ask mb-1">{{ $t("yaya.ask1") }}</div>
              <div class="ans">{{ $t("yaya.ans11") }}</div>
              <div class="ans mb-2">{{ $t("yaya.ans12") }}</div>
              <div class="pic">
                <img class="size" src="../../assets/mobile/yayaphone.png" alt="">
              </div>
              <div class="ask mb-1">{{ $t("yaya.ask2") }}</div>
              <div class="ans">{{ $t("yaya.ans21") }}</div>
              <div class="ans">
                {{ $t("yaya.ans221") }}
                <span class="redtext">{{ $t("yaya.ans222") }}</span>
                {{ $t("yaya.ans223") }}
                <span class="redtext">{{ $t("yaya.ans224") }}</span>
                {{ $t("yaya.ans225") }}
                <span class="redtext">{{ $t("yaya.ans226") }}</span>
                {{ $t("yaya.ans227") }}
                <span class="redtext">{{ $t("yaya.ans228") }}</span>
                {{ $t("yaya.ans229") }}
                <span class="redtext">{{ $t("yaya.ans2210") }}</span>
                {{ $t("yaya.ans2211") }}
              </div>
              <div class="ans mb-2 redline ">{{ $t("yaya.ans23") }}</div>

              <div class="ask mb-1">{{ $t("yaya.ask3") }}</div>
              <div class="ans mb-2">{{ $t("yaya.ans31") }}</div>
              <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div>{{ $t('yaya.ans321') }}</div></div>
              <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div>{{ $t('yaya.ans322') }}</div></div>
              <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div>{{ $t('yaya.ans323') }}</div></div>
              <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div>{{ $t('yaya.ans324') }}</div></div>
              <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div>{{ $t('yaya.ans325') }}</div></div>
              <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div>{{ $t('yaya.ans326') }}</div></div>
              <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div>{{ $t('yaya.ans327') }}</div></div>
              <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div>{{ $t('yaya.ans328') }}</div></div>
              <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div>{{ $t('yaya.ans329') }}</div></div>
              <div class="list my-2 d-flex align-start"><img class="size mr-2" src="../../assets/mobile/heart.png" alt=""><div>{{ $t('yaya.ans3210') }}</div></div>

              <div class="ask mb-1">{{ $t("yaya.ask4") }}</div>
              <div class="ans mb-1">{{ $t("yaya.ans41") }}</div>
            </div>
          </div>
        </div>
        <div class="pub1">
          <img src="../../assets/mobile/b3.png" class="size">
        </div>
        <div class="pub2">
          <img src="../../assets/mobile/b4.png" class="size">
        </div>
        <div class="pub3">
          <img src="../../assets/mobile/b3.png" class="size">
        </div>
      </div>
      <Price />
    </div>
  </v-app>
</template>
<script>
import Unmenu from '../../components/mobile/unmenu.vue'
import Price from '../../components/mobile/price.vue'
export default {
  components: {
    Unmenu,
    Price
  }
}
</script>

<style lang="scss" scoped>
.pmain {
  z-index: 1;
  position: relative;
  .rows {
    width: 90%;
    margin: 0 auto;
    .word {
      .th1 {
        margin-left: -1%;
        width: 90%;
        .size {
          width: 100%;
        }
      }
      .app {
        .icon {
          width: 40%;
          .size {
            width: 100%;
          }
        }
        .mid {
          width: 8%;
        }
        .mid2 {
          width: 2%;
        }
        .btn {
          width:-webkit-fit-content;
          width:-moz-fit-content;
          width:fit-content;
          height: 40px;
          background: #5127E3;
          border-radius: 40px;
          margin: 0 auto;
          cursor: pointer;
          .size {
            width: 18px;
            height: 24px;
          }
          .tt{
            font-size: 18px;
            font-family: Roboto, Roboto-Black;
            font-weight: 900;
            color: #ffffff;
            line-height:24px;
            height: 24px;
          }
        }
      }
      .pic {
        margin: 0 auto;
      .size {
        width: 100%;
      }
    }
      .ans{
        font-size: 14px;
        font-family: Roboto, Roboto-Medium;
        font-weight: 400;
        color: #182639;
        line-height: 20px;
        text-align: justify;
      }
      .ask {
        font-size: 20px;
        font-family: Roboto, Roboto-Black;
        font-weight: 900;
        color: #131415;
        line-height: 26px;
      }
      .redtext {
        color: #ff0300;
      }
      .redline {
        color: #ff0300;
        text-decoration: underline;
      }
      .list {
        font-size: 14px;
        font-family: Roboto, Roboto-Bold;
        font-weight: 500;
        color: #ff0300;
        line-height: 20px;
        .size {
          width: 1.2rem;
          vertical-align: middle;
        }
      }
    }
  }
  .pub1 {
    width: 10%;
    position: absolute;
    top: 22%;
    left: -5%;
    .size{
      width: 100%;
    }
  }
  .pub2 {
    width: 12%;
    position: absolute;
    top: 3rem;
    right: 0;
    .size{
      width: 100%;
    }
  }
  .pub3 {
    width: 10%;
    position: absolute;
    top: -1rem;
    left: -5%;
    .size{
      width: 100%;
    }
  }
}
</style>
