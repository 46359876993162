import { render, staticRenderFns } from "./yumyyaya.vue?vue&type=template&id=7988c0ae&scoped=true&"
import script from "./yumyyaya.vue?vue&type=script&lang=js&"
export * from "./yumyyaya.vue?vue&type=script&lang=js&"
import style0 from "./yumyyaya.vue?vue&type=style&index=0&id=7988c0ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7988c0ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
